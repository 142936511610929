@import '../../../../scss/_theme-bootstrap';

.gnav-utility-item {
  font-family: $font--helvetica-neue;
  &-trigger-label {
    color: $color-white;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 1.4;
    letter-spacing: normal;
    text-decoration: none;
    padding: 0 15px;
    border: 2px solid black;
    border-bottom: none;
    background: none;
    height: $offerbar-height;
    min-height: $offerbar-height;
    font-family: $font--helvetica-neue;
    font-size: 15px;
    .gnav-utility-item--active & {
      color: $color-black;
    }
  }
  &-overlay {
    position: static;
    [role='dialog'] {
      box-sizing: border-box;
      border: 2px solid $color-black;
      border-top: none;
      background-color: $color-white;
      min-height: 100vh;
      z-index: $gnav-fixed-z-index + 1;
      @include breakpoint($landscape-up) {
        min-height: auto;
        position: absolute;
        min-width: 440px;
        #{$rdirection}: 0;
        top: $offerbar-height;
        padding: 40px;
      }
      .site-header-formatter__account & {
        display: block;
        @include swap_direction(padding, 30px 50px 30px 40px);
        font-size: 12px;
        text-transform: uppercase;
        overflow: auto;
        max-height: 100vh;
      }
      .site-header-formatter__email-signup & {
        @include swap_direction(padding, 30px 30px 10px 40px);
      }
    }
    &__close {
      position: absolute;
      border: none;
      background: none;
      padding: 5px;
      #{$rdirection}: 5px;
      top: 5px;
    }
    &__icon--close {
      height: 20px;
      width: 20px;
    }
  }
  a.gnav-utility-item-trigger-label {
    -webkit-font-smoothing: antialiased;
    &:hover {
      color: $color-white;
    }
  }
}
